import type { BaseWidgetData } from '~/@types/cms';

export interface IWidgetProps<T> {
    data: T;
    componentIndex?: number;
}

export interface UrlLink {
    url?: string;
    label?: string | null;
    target?: string;
}

export const useWidget = <T extends BaseWidgetData>(props: IWidgetProps<T>) => {
    const getValueSequence = <TM extends BaseWidgetData>(value: WidgetList<TM>) => {
        if (!(value && value.sequence && value.components)) return [];
        return value.sequence.map((id) => value.components.find((c) => c.id === id)).filter(isDefined);
    };

    return {
        data: props.data,
        getValueSequence,
    };
};
